import React, { Component } from "react"
import "styles/pages/about.scss"

// Components
import Layout from "components/Layout"
import SEO from "components/SEO/"
import profileImage from "images/about/naufal-denmark.jpg"


class About extends Component {

  render() {
    return (
      <Layout>
        <SEO title="About" pathname={this.props.location.pathname} />
        <div className="about-page container">
          <div className="heading">
            <p>I'm Naufal, a software engineer interested in building apps that enrich people's lives approaching them with design and accessibility in mind.</p>
            <p>Right now I'm building cool stuff at <a href="https://www.edreams.com" target="_blank" rel="noopener noreferrer">eDreams</a>, where we help people travel the world.</p>
          </div>
          <figure class="profile">
            <div className="image-wrapper">
              <img src={profileImage} loading="lazy" alt="Profile" />
            </div>
            <figcaption className="caption">That's me in freezing Denmark!</figcaption>
          </figure>
          <div className="segment about">
            <h2 className="title">About</h2>
            <div className="content">
              <p>I used to work at <a href="https://www.accenture.com/es-es/service-liquid-squad-accenture-digital" target="_blank" rel="noopener noreferrer">Liquid Squad</a>, my first job right after college. I started as an intern but the experiences I lived there made me come back as a full time employee. At Liquid Squad I had the opportunity to make a big impact at different organizations ranging from fintech to healthcare</p>
              <p>These challenges gave me the opportunity to expand my technical skills beyond the mobile world, allowing me to learn more about backend and IoT development. However, it wasn't just the technical skills I gained that I'm proudest of, but the high standards and kindness with which employees were treated. The inclusive and open culture that allowed everyone of us to be who really are, the empathy and the sincere admiration for each other are a set of values that stuck deep inside me and I'll be forever grateful for.</p>
              <p>I'm just a simple man, trying to make my way in the universe <b>(ping me if you get the reference, we already have something in common)</b>. Whatever it is, I try to keep everything I do as easy and minimal as possible whether it's work or life. You know... most of the time owning and knowing the least is what makes us happy.</p>
              <p>Wanna know me? Just send me an <a href="mailto:naufalaros@gmail.com">email</a> or hit me up on <a href="https://twitter.com/nauaros">twitter</a> and we can chat or even grab a coffee. I love meeting new folks so don't hesitate about it, just do it!</p>
            </div>
          </div>
          <div className="segment experience">
            <h2 className="title">Experience</h2>
            <div className="content">
              <div className="work">
                <p className="company"><a href="https://www.edreamsodigeo.com">eDreams ODIGEO</a></p>
                <p className="position">iOS Software Engineer</p>
                <p className="info">Jul '19 - Present • Madrid, Spain & Remote</p>
                <div className="description">
                  <p>I'm working on the Booking team, focusing on improving the flight booking experience and contributing to many areas of our apps: eDreams, Opodo, GoVoyages and Travellink.</p>
                </div>
              </div>
              <div className="work">
                <p className="company"><a href="https://www.accenture.com/es-es/service-liquid-squad-accenture-digital">Liquid Squad</a></p>
                <p className="position">iOS Engineer</p>
                <p className="info">Jan '18 - Jul '19 • Madrid, Spain</p>
                <div className="description">
                  <p>Worked on the mobile development team designing and building apps for companies like Carrefour, Santander, Simon or BBVA. During my time at Liquid Squad I helped create new apps from scratch and improve existing ones.</p>
                  <p>My work was not only limited to mobile development. I was actively involved in IoT development where I contributed to R&D projects in order to explore new growth paths and ways of helping our clients.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="segment education">
            <h2 className="title">Education</h2>
            <div className="content">
              <p>I studied at the University of Valladolid where I majored in <b>Computer Engineering</b> in 2018.</p>
              <p>There, I was fortunate enough to be involved in my faculty's life where I was an active member of the computer science club, organized tech conferences and presented workshops to introduce students to the iOS development world.</p>
              <p>It was one of the best and most defining times in my life where I discovered a lot about me and the world. The friendships I forged, the professors I met, the debates I got involved in and the hardships I went through made who I am today.</p>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default About